import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layouts/Default'

import Seo from './../components/Seo'

function contact() {
  return (
    <Layout>
      <div className="contact basic">
        <Seo meta="contact" />
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <h3>{'Contact'}</h3>
        <div className="image">
          <img src="/assets/images/family.jpg" alt={`Hi, I'm Adrian`} />
        </div>
        <p>
          {`Hi, I'd love to hear from you with any suggestions, improvements,
            corrections, gripes, or if you just to say hi.`}
        </p>
        <p>
          {`If you want to get updated when I put new stuff on the site, the
            best places are probably through Twitter – `}
          <a href="https://twitter.com/DrLingua">{`@AdHominum`}</a>
          {`, or `}
          <a href="mailto:adrian@drlingua.com">{`email me`}</a>
          {`.`}
        </p>
        <p>
          {`I'm especially interested in hearing from teachers of Japanese as
          a foreign language. My main focus with this is to make educational
          Japanese language games for kids. If you're a teacher who wishes they
          had a specific resource as a web game, please let me know. I'd be
          delighted to help out where I can.`}
        </p>
        <p>{`Cheers, Adrian`}</p>
      </div>
    </Layout>
  )
}

export default contact
